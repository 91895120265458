import { useMutation, useQuery } from "react-query";
import { db, functions } from "../config/firebase";
import { useAuth } from "../providers/AuthProvider";
import { Reward } from "../types/Reward";
import { queryClient } from "../providers";
import useFirestoreRealtimeData from "./useFirestoreRealtimeData";
import { RewardRedemption } from "../types/RewardRedemption";
import useFirestoreRealtimeDoc from "./useFirestoreRealtimeDoc";

const useRewards = () => {
  const { userData } = useAuth();
  const addReward = useMutation({
    mutationFn: async (data: any) => {
      const rewardData = {
        ...data,
        corporate: userData?.corporate,
      };
      const cleanData = JSON.parse(JSON.stringify(rewardData));
      await db.collection("rewards").add(cleanData);
      await queryClient.invalidateQueries(["get-rewards"]);
    },
  });

  const updateReward = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: any }) => {
      const cleanData = JSON.parse(JSON.stringify(data));
      await db.collection("rewards").doc(id).update(cleanData);
      await queryClient.invalidateQueries(["get-rewards"]);
    },
  });

  const deleteReward = useMutation({
    mutationFn: async (id?: string) => {
      if (!id) return;
      await db.collection("rewards").doc(id).delete();
      await queryClient.invalidateQueries(["get-rewards"]);
    },
  });

  const useGetRewards = () =>
    useQuery({
      queryKey: ["get-rewards", userData?.id],
      queryFn: async () => {
        const getRewardsCFn = functions.httpsCallable("getRewards");
        const res = await getRewardsCFn({ pageSize: 100 });
        return res.data as Reward[];
      },
    });

  const publishReward = useMutation({
    mutationFn: async (id: string) => {
      await db.collection("rewards").doc(id).update({ status: "active" });
      await queryClient.invalidateQueries(["get-rewards"]);
    },
  });

  const useDecodedRewardRedemptionData = (token: string) =>
    useQuery({
      queryKey: ["decoded-reward-redemption-data", token],
      queryFn: async () => {
        const getRewardRedemptionDataCFn = functions.httpsCallable(
          "getRewardRedemptionData"
        );
        const res = await getRewardRedemptionDataCFn({ token });
        return res.data;
      },
    });

  const markRewardAsFulfilled = useMutation({
    mutationFn: async (redemptionId: string) => {
      const markRewardAsFulfilledCFn = functions.httpsCallable(
        "markRewardAsFulfilled"
      );
      await markRewardAsFulfilledCFn({ redemptionId });
      await queryClient.invalidateQueries(["decoded-reward-redemption-data"]);
    },
  });

  return {
    addReward,
    updateReward,
    deleteReward,
    useGetRewards,
    publishReward,
    useDecodedRewardRedemptionData,
    markRewardAsFulfilled,
  };
};

export default useRewards;
