import moment from "moment";
import colors from "../assets/colors";
import Constants from "../common/Constants";
import ImageIcon from "../components/common/ImageIcon";
import useStats from "../hooks/useStats";
import { Log } from "../types/Log";

const Settings = () => {
  const { useNewUsersStats, useGetLogs } = useStats();

  const { data: logsData } = useGetLogs();

  const { data: newUsersStat } = useNewUsersStats();
  return (
    <div className="flex-1 bg-gray-50">
      <div
        className="p-12 overflow-y-auto"
        style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }}
      >
        <div className="flex mb-6 items-center gap-4">
          <p className="text-2xl font-bold">Activity Logs</p>
        </div>

        <div className="gap-2 flex flex-col items-start">
          {logsData?.pages
            .map((p) => p.list)
            .reduce((t, c) => [...t, ...c], [])
            .map((log, index) => (
              <LogItem item={log} key={index} />
            ))}
        </div>

        {/* <div className="flex p-2">
          <div className="h-40 border border-gray-200 bg-white rounded-xl w-80 p-4 flex flex-col">
            <div className="flex justify-between items-center">
              <div className="flex flex-col ">
                <p className="font-bold text-gray-500">
                  NEW USERS (Last 30 days)
                </p>
                <p className="font-extrabold text-2xl self-start ">
                  {newUsersStat?.last30Days || 0}
                </p>
              </div>
              <ImageIcon
                size={40}
                color={colors.PRIMARY}
                name="personal_account"
              />
            </div>
            <div className="flex bg-gray-50 rounded mt-auto p-1 px-2">
              <p className="text-sm">
                30 days before the last : {newUsersStat?.previous30Days} new
                users
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Settings;

const LogItem = ({ item }: { item: Log }) => {
  return (
    <div className="bg-white rounded-lg border flex flex-col items-start px-4 py-2 min-w-80">
      <p className="font-bold">{item.title}</p>
      <p className="text-sm text-gray-400">
        {moment(item.created_at).format("hh:mm a DD-MM-YYYY")}
      </p>
    </div>
  );
};
