import { ReactNode, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation, useToggle } from "react-use";
import colors from "../../assets/colors";
import Images from "../../assets/Images";
import Constants from "../../common/Constants";
import { useAuth } from "../../providers/AuthProvider";
import ImageIcon from "../common/ImageIcon";
import { Roles } from "../../types/Role";

const Header = ({ right }: { right?: ReactNode }) => {
  const [menuOpen, toggleMenu] = useToggle(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useAuth();
  const params = useParams();

  const menuItems = useMemo(
    () =>
      [
        { label: "Overview", to: "" },
        { label: "Statistics", to: "statistics", coming_soon: true },
        { label: "Trip History", to: "trip-history", coming_soon: true },
        { label: "Badge and NFTs", to: "badge-and-nfts", coming_soon: true },
        {
          label: "Settings",
          to: "settings",
          coming_soon: userData?.role !== Roles.ClientAdmin,
        },
        { label: "Marketplace", to: "marketplace" },
        userData ? { label: "Gamification", to: "gamification" } : undefined,
        userData?.role === Roles.Admin
          ? { label: "Users", to: "users" }
          : undefined,
        userData?.selected_plan_type == "corporate_manager"
          ? { label: "Bonuses", to: "bonuses" }
          : undefined,
      ].filter((item) => item != undefined),
    [userData]
  );

  return (
    <div
      style={{
        display: "flex",
        background: colors.WHITE,
        padding: "0px 40px",
        height: Constants.header_height,
        alignItems: "center",
        zIndex: 5,
        borderBottomWidth: 1,
        borderColor: colors.BLACK_10,
        borderStyle: "solid",
      }}
    >
      <img alt="logo" style={{ height: 43 }} src={Images.the_carbon_games} />

      <ImageIcon
        onClick={toggleMenu}
        color={menuOpen ? colors.WHITE : colors.BLACK_100}
        containerStyle={{
          background: menuOpen ? colors.BLACK_100 : colors.BLACK_20,
          padding: 15,
          borderRadius: 13,
          marginLeft: 20,
          zIndex: 10,
        }}
        name="menu"
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: menuOpen ? 10 : -10,
          transition: "all 200ms ease",
          opacity: menuOpen ? 1 : 0,
        }}
      >
        {menuItems.map((menu, index) => (
          <MenuItem
            selectedMenu={
              location.pathname?.split("/")[params.slug ? 2 : 1] || ""
            }
            onClick={() => (menuOpen ? navigate(menu!.to) : null)}
            menu={menu!}
            key={index}
          />
        ))}
      </div>

      <div style={{ flex: 1 }} />
      {right}
    </div>
  );
};

export default Header;

const MenuItem = ({
  menu,
  onClick,
  selectedMenu,
}: {
  menu: { label: string; to: string; coming_soon?: boolean };
  onClick: () => void;
  selectedMenu: string;
}) => {
  const selected = menu.to === selectedMenu;
  return (
    <div className="relative">
      <p
        className="hover-tint"
        onClick={onClick}
        style={{
          padding: "2px 10px",
          color: selected ? colors.PRIMARY : colors.BLACK_60,
          fontSize: 13,
          cursor: "pointer",
        }}
      >
        {menu.label}
      </p>
      {menu.coming_soon && (
        <p
          style={{ fontSize: 9 }}
          className="absolute italic top-[23px] text-center left-0 right-0 text-gray-300"
        >
          Coming soon
        </p>
      )}
    </div>
  );
};
