import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import ControlledInput from "../../components/common/ControlledInput";
import CustomButton from "../../components/common/CustomButton";
import ModalHeader from "../../components/common/ModalHeader";
import colors from "../../assets/colors";
import ControlledPicker from "../../components/common/ControlledPicker";
import { GamificationTemplate } from "../../types/Gamification";
import useGamification from "../../hooks/useGamification";

export type EditGamificationCardModalProps = {
  card: GamificationTemplate["cards"][number];
};

type FieldValues = {
  multiplier: number;
  status: "active" | "inactive";
  completion_threshold?: number;
  default_user_status?: "active" | "inactive";
  id: string;
};

const EditGamificationCard = ({
  hide,
  props,
}: {
  hide?: () => void;
  props?: EditGamificationCardModalProps;
}) => {
  const { updateGamificationCard: update } = useGamification();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FieldValues>({
    defaultValues: props?.card,
  });

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      update.mutate(data, { onSuccess: hide });
    },
    [update, hide]
  );

  return (
    <div style={{ margin: -30, minWidth: 500 }}>
      {hide && (
        <ModalHeader
          style={{ padding: 20 }}
          hide={hide}
          title="Edit Card"
          subtitle="New points will be calculated based on the updated you make."
        />
      )}

      {hide && <div style={{ height: 1, background: colors.BLACK_20 }} />}

      <div
        style={{
          overflowY: "scroll",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
          maxHeight: "75vh",
          padding: 30,
        }}
      >
        <ControlledInput
          label="Multiplier"
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "multiplier",
            options: { required: true, valueAsNumber: true },
          }}
        />

        <div className="flex gap-5 mb-4">
          <ControlledPicker
            label="Status"
            containerStyle={{ flex: 1 }}
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
            formProps={{
              errors,
              name: "status",
              options: { required: true },
              control,
            }}
          />

          <ControlledPicker
            label="Default status for users"
            containerStyle={{ flex: 1 }}
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
            formProps={{
              errors,
              name: "default_user_status",
              options: { required: false },
              control,
            }}
          />
        </div>

        {props?.card.completion_threshold && (
          <ControlledInput
            label="Completion Threshold"
            containerStyle={{ marginBottom: 15 }}
            formProps={{
              register,
              errors,
              name: "completion_threshold",
              options: { required: false, valueAsNumber: true },
            }}
          />
        )}
      </div>

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <div
        style={{ display: "flex", alignItems: "center", padding: 20, gap: 20 }}
      >
        <CustomButton
          onClick={handleSubmit(onSubmit)}
          loading={update.isLoading}
          style={{ flex: 1 }}
          label={props?.card ? "Update" : "Create"}
        />
      </div>
    </div>
  );
};

export default EditGamificationCard;
