import { CSSProperties, useMemo } from "react";
import { MdClose } from "react-icons/md";

import { shadow } from "../../assets/styles";
import colors from "../../assets/colors";

type Props<TValue> = {
  options: Option<TValue>[];
  value?: TValue;
  onSelect: (value?: TValue) => void;
  style?: CSSProperties;
  tabStyle?: CSSProperties;
  hidable?: boolean;
};

const Tabs = <TValue,>({
  options,
  value,
  onSelect,
  style,
  tabStyle,
  hidable = true,
}: Props<TValue>) => {
  const OptionItem = ({ option }: { option: Option<TValue> }) => {
    const selected = useMemo(() => option.value === value, [option.value]);
    return (
      <div
        className={selected ? "" : "hover-opacity"}
        onClick={() => onSelect(option.value)}
        style={{
          backgroundColor: selected ? colors.BLACK_80 : colors.WHITE,
          padding: "13px 20px",
          flex: 1,
          cursor: "pointer",
          borderRadius: 6,
          ...tabStyle,
        }}
      >
        <p
          style={{
            color: selected ? colors.WHITE : colors.BLACK_80,
            fontSize: 13,
            fontWeight: "500",
          }}
        >
          {option.label}
        </p>
      </div>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {value !== undefined && hidable && (
        <div
          onClick={() => onSelect(undefined)}
          className="hover-opacity"
          style={{
            position: "absolute",
            background: colors.LIGHT_GREEN,
            right: -50,
            top: 5,
            bottom: 5,
            borderRadius: 105,
            width: 40,
            ...shadow,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            pointerEvents: "all",
            cursor: "pointer",
          }}
        >
          <MdClose opacity={0.6} size={30} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          pointerEvents: "all",
          borderRadius: 10,
          borderWidth: 4,
          borderColor: colors.WHITE,
          overflow: "hidden",
          borderStyle: "solid",
          backgroundColor: colors.WHITE,
          gap: 1,
          ...shadow,
          ...style,
        }}
      >
        {options.map((option, index) => (
          <OptionItem option={option} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Tabs;

export type Option<TValue> = { label: string; value: TValue };
